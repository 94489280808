import React, { useEffect, useState, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import './PoCreate.css';
import Select from 'react-select';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ASSET_PREFIX_URL, BASE_URL, PHOTO_LINK } from '../../constants/config';
import NameProfile from '../Common/CommonJsx/NameProfile';

function PoTemplateFields({ mandatoryFields = [], fieldsType, setData, poType, inputDisable, fieldErrors, setFieldErrors,

    isDropdownOpen, setIsDropdownOpen, setSelectedAddress, selectedAddresses,

    setSelectedWarehouse,
    setSelectedDepartment,
    selectedDepartment
}) {

    const [selectMember, setSelectMember] = useState([]);
    const [warehouseAddress, setWarehouseAddress] = useState({});
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);


    const fetchMembers = async (search) => {
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const response = await axios.get(
                `${BASE_URL}/v1/ec-template/get-members-hierarchy`,
                { headers, params: { search: search } }
            );
            setSelectMember(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const toggleDropdown = (e) => {
        fetchMembers();
        setIsDropdownOpen(!isDropdownOpen);
        e.stopPropagation();
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };

    const initializeFormData = () => {
        let initialData = {};
        mandatoryFields.forEach(field => {
            const transformedFieldName = field.fieldName.replace(/\s+/g, '_').toLowerCase();
            if (field.inputType === 'date_range') {
                initialData[transformedFieldName] = ['', ''];
            } else {
                initialData[transformedFieldName] = '';
            }
        });
        return initialData;
    };

    const [formData, setFormData] = useState(initializeFormData());

    const handlePersonSelectChange = (fieldName, value) => {
        let fieldValue = [
            value.label.props.children[0].props.memberPhoto ? value.label.props.children[0].props.memberPhoto : '',
            value.value,
            value.label.props.children[1],
        ];
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = { ...formData, [transformedFieldName]: value };
        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, fieldValue);

        // Clear field error
        setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };

    const handleMultiSelectChange = (fieldName, value) => {
        let fieldValue = value.map(item => item.label);
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = { ...formData, [transformedFieldName]: value };
        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, fieldValue);

        // Clear field error
        setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };

    const handleChange = (fieldName, value, dateType) => {
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        let updatedFormData = { ...formData };

        if (dateType === 'start') {
            if (typeof updatedFormData[transformedFieldName] === 'string') {
                updatedFormData[transformedFieldName] = [format(value.start, 'yyyy-MM-dd'), format(value.end, 'yyyy-MM-dd')];
            }

        } else {
            updatedFormData[transformedFieldName] = value;
        }

        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, updatedFormData[transformedFieldName]);

        // Clear field error
        setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };


    useEffect(() => {
        // Initialize selectedMembers with the default approver member if available
        let approverMember = mandatoryFields.find(item => item.template_type === 'approvermember')?.value;
        if (approverMember) {
            const defaultMember = {
                _id: approverMember.member_id,
                fullName: approverMember.fullName,
                photo: approverMember.photo,
                status: ''
            };
            setSelectedMembers([defaultMember]);
            setFieldErrors(prevErrors => ({ ...prevErrors, ['Approver']: '' }));
        }

        // Initialize field errors
        const initialFieldErrors = {};

        // Check for fields with is_default true and their values are empty
        mandatoryFields.forEach(field => {
            const transformedFieldName = field.fieldName.replace(/\s+/g, '_').toLowerCase();
            if (field.template_type !== 'poItems' && field.template_type !== 'approvermember' && field.is_default === true &&
                field.template_type !== 'tandc' && field.fieldName !== 'Status' &&
                field.fieldName !== 'Supplier reference invoice' && !formData[field.fieldName]) {
                initialFieldErrors[field.fieldName] = 'This field is required';
            }



        });

        // Check specific condition for Approver field
        if (fieldsType === 'mandate') {
            const statusField = mandatoryFields.find(field => field.fieldName === 'Status' && field.is_default);
            if (statusField) {
                setData(prevData => ({
                    ...prevData,
                    status: {
                        value: 'Created', // Default value
                        form_field_id: statusField._id,
                    }
                }));
            }
        }


        // Set field errors
        setFieldErrors(initialFieldErrors);
        // if(!selectedAddresses){
        //     setAddressError('This field is required')
        // }
        // if(!selectedDepartment){
        //     setDepartmentError('This field is required')
        // }
    }, [mandatoryFields]);


    const handleMemberClick = (member, e, fieldName) => {
        e.stopPropagation();
        let transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const memberId = member.entity_id._id;
        const fullName = member.entity_id?.fullName;
        const photo = member.entity_id?.photo;

        const approverMemberId = mandatoryFields.find(item => item.template_type === 'approvermember')?.value?.member_id;

        // Prevent removal of default approver member
        if (memberId === approverMemberId) {
            return;
        }

        let updatedSelectedMembers;
        if (selectedMembers.some(selectedMember => selectedMember._id === memberId)) {
            updatedSelectedMembers = selectedMembers.filter(selectedMember => selectedMember._id !== memberId);
        } else {
            updatedSelectedMembers = [...selectedMembers, { _id: memberId, fullName, photo, status: '' }];
        }

        // Update formData with selected members data
        const updatedFormData = updatedSelectedMembers.map(member => ({
            _id: member._id,
            fullName: member.fullName,
            photo: member.photo,
            status: member.status
        }));
        setData(prevErrors => ({
            ...prevErrors,
            [transformedFieldName]: {
                value: updatedFormData.value,
                form_field_id: updatedFormData._id
            }
        }));

        saveFieldData(transformedFieldName, updatedFormData);
        setSelectedMembers(updatedSelectedMembers);



        setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };



    const saveFieldData = (fieldName, value) => {
        const field = mandatoryFields.find(f => f.fieldName.replace(/\s+/g, '_').toLowerCase() === fieldName);
        if (!field) return;
        let fieldData;
        // const field = mandatoryFields.find(f => f.fieldName.replace(/\s+/g, '_').toLowerCase() === 'status')

        if (field.inputType === 'single_select') {
            fieldData = {
                value: value.label,
                is_default: field.is_default,
                inputType: field.inputType,
                fieldName: field.fieldName,
                options: field.options,
                order: mandatoryFields.indexOf(field) + 1,
                form_field_id: field._id
            };
        } else {
            fieldData = {
                value: value,
                is_default: field.is_default,
                inputType: field.inputType,
                fieldName: field.fieldName,
                options: field.options,
                order: mandatoryFields.indexOf(field) + 1,
                form_field_id: field._id
            };
        }
        setData(prevData => ({
            ...prevData,
            [fieldName]: {
                value: fieldData.value, // Extract only the value
                form_field_id: fieldData.form_field_id      // Extract only the _id
            }
        }));

    };

    let filteredFields;
    if (poType === 'create' || poType === 'assets') {
        filteredFields =
            fieldsType === 'mandate'
                ? mandatoryFields.filter(field => field.template_type === 'purchaseTemplate' && field.is_default)
                : mandatoryFields.filter(field => field.template_type === 'purchaseTemplate' && !field.is_default && field.is_default_template);
    } else {
        filteredFields = mandatoryFields;
    }
    const handleAddressChange = async (selectedOption, action) => {
        if (action === 'address') {
            setSelectedAddress(selectedOption.label);
            setSelectedWarehouse(selectedOption.value)
        } else {
            setSelectedDepartment(selectedOption.value)
        }

    }



    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    const formatDateRange = () => {
        const startDate = format(state[0].startDate, 'yyyy-MM-dd');
        const endDate = format(state[0].endDate, 'yyyy-MM-dd');
        return `${startDate} to ${endDate}`;
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);




    const fetchWarehouseDetails = async () => {
        try {
            // setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/warehouse/get-warehouse-list", { headers });

            setWarehouseAddress(response.data.data.warehouse_list)


        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/depart/get-departments", { headers });
            const departments = response.data.data.departments;

            setDepartmentList(departments);
            //   fillInputSetsWithDepartments(departments);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    return (
        <>
            {/* Conditionally render a horizontal line if there are items that are default */}
            {mandatoryFields.filter(item => item.is_default && item.template_type === 'purchaseTemplate' &&
                !item.is_default_template
            ).length !== 0 && fieldsType === 'mandate' &&
                <div className='horizontal-line-po'></div>
            }

            {/* Conditionally render another horizontal line if there are items that are not default */}
            {mandatoryFields.filter(item =>
                item.is_default_template &&
                item.template_type === 'purchaseTemplate' &&
                !item.is_default
            ).length !== 0 && fieldsType === 'custom' &&
                <div className='horizontal-line-po'></div>
            }

            {/* Conditionally render 'Custom fields' if there are custom fields */}
            {mandatoryFields.filter(item =>
                item.is_default_template &&
                item.template_type === 'purchaseTemplate' &&
                !item.is_default
            ).length !== 0 &&
                fieldsType === 'custom' &&
                <span>Custom fields</span>
            }

            {/* <div className='horizontal-line-po'></div> */}
            {/* <span>{fieldsType === 'mandate' ? '' : 'Custom fields'}</span> */}
            <div className='po-template-fields' onClick={() => setIsDropdownOpen(false)} style={{ marginTop: '12px', marginBottom: '24px' }}>
                {filteredFields.map((field, index) => (
                    <div key={index} className='po-mandatory-field-container'>
                        <span>{field.fieldName}</span>
                        {(field.inputType === 'text') && (
                            <input
                                type='text'
                                disabled={!inputDisable}
                                placeholder={field.placeholder || ''}
                                value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                onChange={(e) => handleChange(field.fieldName, e.target.value)}
                            />
                        )}
                        {field.inputType === 'paragraph' &&
                            <textarea
                                type='text'
                                disabled={!inputDisable}
                                placeholder={field.placeholder || ''}
                                value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                onChange={(e) => handleChange(field.fieldName, e.target.value)}
                            />
                        }

                        {(field.inputType === 'link') && (
                            <input
                                type='url'
                                disabled={!inputDisable}
                                placeholder={field.placeholder || ''}
                                value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                onChange={(e) => handleChange(field.fieldName, e.target.value)}
                            />
                        )}
                        {(field.inputType === 'number') && (
                            <input
                                type='number'
                                disabled={!inputDisable}
                                placeholder={field.placeholder || ''}
                                value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                onChange={(e) => handleChange(field.fieldName, e.target.value)}
                            />
                        )}
                        {field.inputType === 'date_range' && (
                            <div className='date-range-input-relative'>
                                <input
                                    value={formatDateRange()}
                                    onClick={toggleOpen}
                                    readOnly
                                    style={{ cursor: 'pointer' }}

                                />
                                {open && (
                                    <div ref={ref} style={{ position: 'absolute', zIndex: 1, background: 'white' }}>
                                        <DateRangePicker
                                            onChange={(item) => {
                                                handleChange(field.fieldName, { start: item.selection.startDate, end: item.selection.endDate }, 'start');
                                                // handleChange(field.fieldName, item.selection.endDate);
                                            }}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            ranges={state}
                                            direction="horizontal"
                                        // onChange={(item) => setState([item.selection])}
                                        />
                                    </div>
                                )}
                            </div>


                        )}
                        {field.inputType === 'single_select' && (
                            <Select
                                styles={customStyles}
                                isDisabled={!inputDisable || field.fieldName === 'Status'}
                                options={field.options.map(option => ({ value: option, label: option }))}
                                value={
                                    formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] ||
                                    (field.fieldName === 'Status' ? { value: 'Created', label: 'Created' } : '')
                                }
                                onChange={(selectedOption) => handleChange(field.fieldName, selectedOption)}
                            />
                        )}
                        {field.inputType === 'multi_select' && (
                            <Select
                                styles={customStyles}
                                isMulti
                                isDisabled={!inputDisable}
                                options={field.options.map(option => ({ value: option, label: option }))}
                                onChange={(selectedOptions) => handleMultiSelectChange(field.fieldName, selectedOptions)}
                            />
                        )}
                        {field.inputType === 'person' && (
                            <Select
                                placeholder={field.placeholder}
                                isDisabled={!inputDisable}
                                styles={customStyles}
                                isMulti={field.fieldName === "Watchers"}
                                options={selectMember.map((member) => ({
                                    value: member.entity_id, // Use full name as the value
                                    label: (
                                        <div
                                            className="member-option"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <NameProfile userName={member.fullName} width='26px' memberPhoto={member.photo} fontweight='500' />
                                            {member.fullName}
                                        </div>
                                    ),
                                }))}
                                onChange={(selectedOption) => {
                                    field.fieldName === "Watchers"
                                        ? handlePersonSelectChange(
                                            field.fieldName,
                                            selectedOption
                                        )
                                        : handlePersonSelectChange(
                                            field.fieldName,
                                            selectedOption
                                        );
                                }}
                                value={
                                    formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()]
                                } // Use fullName as value
                            />
                        )}
                        {field.inputType === 'date' && (
                            <input
                                type='date'
                                disabled={!inputDisable}
                                min={field.fieldName !== 'PO Date' ? new Date().toISOString().split("T")[0] : ''}
                                value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                onChange={(e) => handleChange(field.fieldName, e.target.value)}
                            />
                        )}
                        {field.inputType === 'select-person' && (
                            <>
                                <div className="create-part-num-div-form-input-options-po">
                                    <div className='create-part-num-div-form-input-options-drop' onClick={(e) => toggleDropdown(e)}>
                                        <input
                                            style={{ border: 'none' }}
                                            type="text"
                                            placeholder="Select Approver(s)"
                                            readOnly
                                        />
                                        <div className='droping-search-div'>
                                            <img className='droping-search' src={`${ASSET_PREFIX_URL}options-drop.png`} alt="" />
                                        </div>
                                    </div>
                                    {isDropdownOpen && (
                                        <div className='part-options-child-div' style={{ width: '292px' }}>
                                            {selectMember.map((member, index) => (
                                                <div
                                                    key={member.entity_id._id}
                                                    className={`part-options-child ${selectedMembers.some(selectedMember => selectedMember._id === member.entity_id) ? 'selected' : ''}`}
                                                    onClick={(e) => handleMemberClick(member, e, field.fieldName)}
                                                >
                                                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                        {member.entity_id?.photo ? (
                                                            <img
                                                                src={PHOTO_LINK + member.entity_id?.photo}
                                                                alt=""
                                                                style={{
                                                                    borderRadius: "50%",
                                                                    width: "26px",
                                                                    height: "26px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className="owner-image-span">
                                                                <NameProfile userName={member.entity_id?.fullName} />
                                                            </div>
                                                        )}
                                                        <span>{member.entity_id?.fullName}</span>
                                                    </div>
                                                    <img
                                                        src={selectedMembers.some(selectedMember => selectedMember._id === member.entity_id._id) ? `${ASSET_PREFIX_URL}part-selected.png` : `${ASSET_PREFIX_URL}part-unselect.png`}
                                                        alt="Part"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {selectedMembers.length > 0 && (
                                    <span className="selected-mem-list">
                                        {selectedMembers.map((item, index) => (
                                            <React.Fragment key={item._id}>
                                                {item.fullName}
                                                {index < selectedMembers.length - 1 && ", "}
                                            </React.Fragment>
                                        ))}
                                    </span>
                                )}
                            </>
                        )}
                        <span
                            className="error-message"
                            style={{
                                color: "red",
                                visibility: fieldErrors[field.fieldName] ? "visible" : "hidden"
                            }}
                        >
                            {fieldErrors[field.fieldName] && (
                                <>
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    {fieldErrors[field.fieldName]}
                                </>
                            )}
                        </span>


                    </div>
                ))}
                {fieldsType === 'mandate' &&
                    <div className='po-mandatory-field-container'>
                        <span>Warehouse address</span>
                        <Select
                            // value={{
                            //     value: selectedAddresses,
                            //     label: selectedAddresses
                            // }}

                            styles={customStyles}
                            placeholder="Choose address"
                            onFocus={fetchWarehouseDetails}
                            options={warehouseAddress.length && warehouseAddress.map((item) => ({
                                value: item.warehouse_sequence_id,
                                label: item.warehouse_default_data.warehouse_name.value,
                            }))}
                            onChange={(selectedOption) => handleAddressChange(selectedOption, 'address')}
                        />
                        <span
                            className="error-message"
                            style={{
                                color: "red",
                                visibility: !selectedAddresses ? "visible" : "hidden"
                            }}
                        >
                            {!selectedAddresses && (
                                <>
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    This field is required
                                </>
                            )}
                        </span>
                    </div>
                }
                {fieldsType === 'mandate' &&
                    <div className='po-mandatory-field-container'>
                        <span>Select department</span>
                        <Select
                            onFocus={fetchDepartments}
                            // value={{ value: selectedDepartment, label: selectedDepartment }}

                            styles={customStyles}
                            placeholder="Choose department"
                            options={departmentList.map((item) => ({
                                value: item.entity_id,
                                label: item.department_name,
                            }))}
                            onChange={(selectedOption) => handleAddressChange(selectedOption, 'dept')}
                        />
                        <span
                            className="error-message"
                            style={{
                                color: "red",
                                visibility: !selectedDepartment ? "visible" : "hidden"
                            }}
                        >
                            {!selectedDepartment && (
                                <>
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    This field is required
                                </>
                            )}
                        </span>

                    </div>
                }


            </div>
        </>
    );
}

export default PoTemplateFields;
