import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL, HEADERS } from "../../constants/config";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import DeletePartPopUp from "./DeletePartPopUp";

function AddPartsPopUp({ activeNode, setAction, action, sequence_id, rootnode, type,setUpdatedData }) {

    const [close, setClose] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(action === 'update_quantity' ? activeNode.quantity : "");
    const [parentParts, setParentParts] = useState([]);
    const [selectedParent, setSelectedParent] = useState(null);
    const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
    const [startDate, setStartDate] = useState(action === 'update_quantity' ? activeNode.start_date : "");
    const [endDate, setEndDate] = useState(action === 'update_quantity' ? activeNode.end_date : "");

   

   
    useEffect(() => {
        fetchParentPartsData()
    }, []);

    const fetchParentPartsData = async () => {
        try {
            const headers = {
                'x-auth-token': localStorage.getItem("token")
            };
            if (type === 'ebom') {
                const response = await axios.get(BASE_URL + "/v1/structure-ec/common-parents", {
                    params: { sequence_id, part_number: activeNode.part_number, type },
                    headers: headers,
                });

                console.log(response.data.data.common_parents)
                setParentParts(response.data.data.common_parents)
            } else if (type === 'mbom') {
                const response = await axios.get(BASE_URL + "/v1/manufac-bom/common-parents", {
                    params: { sequence_id, part_number: activeNode.part_number, type },
                    headers: headers,
                });

                console.log(response.data.data.common_parents)
                setParentParts(response.data.data.common_parents)
                console.log(response.data.data.common_parents.length)
            }


        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    useEffect(() => {
        // If there's only one option, select it by default
        if (parentParts.length === 1) {
            setSelectedParent(parentParts[0]);
            console.log('lk')
        }
    }, [parentParts]);

    const selectParentPart = (selected)=>{
        setSelectedParent(selected)
    }
    const selectPart = (selected)=>{
        setSelectedOption(selected)
    }
    const fetchOptions = async ( page) => {
        try {
            const headers = {
                'x-auth-token': localStorage.getItem("token")
            };
            if (type === 'ebom') {
                const response = await axios.get(`${BASE_URL}/v1/purchase-order/get-part-po`, {
                    params: {
                       
                        page: page,
                        size: 10
                    },
                    headers: headers,
                });
                const data = response.data.data.parts;
                setOptions(prevOptions => {
                    if (page === 1) {
                        return data; // Replace the options when it's the first page
                    }
                    return [...prevOptions, ...data]; // Append options for subsequent pages
                });

                setHasMore(data.length > 0);
            } else {
                const response = await axios.get(`${BASE_URL}/v1/manufac-bom/get-approved-pc-parts`, {
                    params: {
                        active_part: activeNode.part_number,
                        page: page,
                        size: 10
                    },
                    headers: headers,
                });
                const data = response.data.data.part_number_list;
                console.log(data)
                setOptions(prevOptions => {
                    if (page === 1) {
                        return data; // Replace the options when it's the first page
                    }
                    return [...prevOptions, ...data]; // Append options for subsequent pages
                });

                setHasMore(data.length > 0);
            }



        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const handleFocus = () => {
        if (options.length === 0) {
          fetchOptions(1); // Load options when the input is focused
        }
      };
    

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
        if (bottom && hasMore) {
            setPage(prevPage => {
                const nextPage = prevPage + 1;
                fetchOptions(nextPage);
                return nextPage;
            });
        }
    };

    const handleClose = () => {
        setClose(true);
        setAction(false);
    };

   

   

    const handleChange = (field, value) => {
        switch (field) {
            case 'quantity':
                setQuantity(value);
                break;
            case 'startDate':
                setStartDate(value);
                break;
            case 'endDate':
                setEndDate(value);
                break;
            default:
                break;
        }

        setValidationErrors(prevErrors => {
            const { [field]: _, ...remainingErrors } = prevErrors;
            return remainingErrors;
        });
    };




    const handleAddPart = async () => {
        setFormSubmitted(true);
        // const errors = validateForm();
        // setValidationErrors(errors);

        // if (Object.keys(errors).length > 0) {
        //     return;
        // }

        if (action !== 'delete') {
            try {
                let url, sequenceId, actionType;
                if (type === 'ebom') {
                    url = BASE_URL + "/v1/structure-ec/update-ebom"
                    sequenceId = 'ec_sequence_id'
                    actionType = 'action'
                } else if (type === 'mbom') {
                    url = BASE_URL + "/v1/manufac-bom/update-mbom"
                    sequenceId = 'sequence_id'
                    actionType = 'type'
                }
                const response = await axios.post(url, {
                    [sequenceId]: sequence_id,
                    [actionType]: action,

                    new_part_number: selectedOption?.part_number,
                    part_number: activeNode.part_number,
                    parent_part_number: selectedParent?.part_number,
                    quantity,
                    start_date: startDate,
                    end_date: endDate,
                },
                    {
                        headers: HEADERS
                    });

                if (rootnode === selectedOption?.part_number) {
                    toast.error("Root node can't be added");
                } else if (activeNode.part_number === selectedOption?.part_number) {
                    toast.error("selected part number and active part number are same");
                } else if (response.data.meta.success === false) {
                    toast.error(response.data.meta.message);
                } else {
                    setUpdatedData(response)
                    setAction(false)
                }
            } catch (error) {
                console.error(error.message);
            }
        } else {

            setOpenDeletePopUp(true);
        }
    };

    



    const customStyles = {
        menu: (provided) => ({
            ...provided,
            overflowY: "auto",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            "::-webkit-scrollbar": "auto",
            "::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "8px",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit", 
        }),
    };

    const filterOptions = (candidate, input) => {
        if (!input) {
            return true;
        }

        const inputValue = input.toLowerCase().trim();
        const part_number = candidate.data.part_number.toLowerCase();
        return part_number.includes(inputValue);
    };
    const handleCloseMenuActions = () => {
        setOpenDeletePopUp(false)

    };

    return (
        <>
            <div className="editRole" style={{ display: close ? "none" : "block" }}>
                <div className="upper-part">
                    <div className="cancel">
                        <span>
                            {action === 'add' && 'Add '}
                            {action === 'replace' && 'Replace '}
                            {action === 'delete' && 'Delete '}
                            {action === 'update_quantity' && 'Update '}
                            part {action === 'delete' && activeNode.part_number}
                        </span>
                        <CloseIcon onClick={handleClose} />
                    </div>

                    {action !== 'add' && (
                        <div className="emp">
                            <span>Parent part number</span>
                            {parentParts.length === 1 ? (
                                // If there's only one option, display it as selected
                                <div className="select-photo">
                                    {parentParts[0].part_number}
                                </div>
                            ) : (
                                // If there are multiple options, render the Select component
                                <Select
                                    id="mySelect1"
                                    options={parentParts}
                                    menuPlacement="auto"
                                    styles={customStyles}
                                    getOptionLabel={(parentParts) => (
                                        <div className="select-photo">
                                            {parentParts.part_number}
                                        </div>
                                    )}
                                    onChange={(selected)=>selectParentPart(selected)}
                                    onMenuScrollToBottom={handleScroll}
                                    filterOption={filterOptions}
                                    onFocus={fetchParentPartsData}
                                    value={selectedParent}
                                />
                            )}
                            {formSubmitted && validationErrors.selectedParent && (
                                <div className="department-error">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />&nbsp;&nbsp;&nbsp;{validationErrors.selectedParent}
                                </div>
                            )}
                        </div>
                    )}
                    {(action !== 'delete' && action !== 'update_quantity') && <div className="emp">
                        <span>Part number</span>
                        <Select
                            id="mySelect2"
                            options={options}
                            menuPlacement="auto"
                            styles={customStyles}
                            getOptionLabel={(option) => (
                                <div className="select-photo">
                                    {option.part_number}&nbsp;
                                    {/* ({option.part_title.length > 12 ? `${option.part_title.slice(0, 18)}...` : option.part_title}) */}
                                </div>
                            )}
                            onChange={(selected)=>selectPart(selected)}
                            onFocus={handleFocus}
                            filterOption={filterOptions}
                            onMenuScrollToBottom={handleScroll}
                            value={selectedOption}
                        />
                        {formSubmitted && validationErrors.selectedOption && (
                            <div className="department-error">
                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />&nbsp;&nbsp;&nbsp;{validationErrors.selectedOption}
                            </div>
                        )}
                    </div>}


                    {action !== 'delete' && (
                        <div className="editJob">
                            <span>Part quantity</span>
                            <input
                                type="number"
                                placeholder="Enter Quantity"
                                onChange={(e) => handleChange('quantity', e.target.value)}
                                value={quantity}
                            />
                            {formSubmitted && validationErrors.quantity && (
                                <div className="department-error">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />&nbsp;&nbsp;&nbsp;{validationErrors.quantity}
                                </div>
                            )}
                        </div>
                    )}
                    {(type === 'mbom' && action !== 'delete') &&
                        <div className="editJob">
                            <span>Start date</span>
                            <input
                                type="date"
                                placeholder="Enter date"
                                min={
                                    activeNode.part_number === rootnode
                                        ? new Date().toISOString().split("T")[0] 
                                        : activeNode.start_date 
                                }
                                max={
                                    activeNode.part_number === rootnode
                                        ? endDate 
                                        : activeNode.end_date 
                                }
                              
                                onChange={(e) => handleChange('startDate', e.target.value)}
                                value={startDate}
                            />

                            {formSubmitted && validationErrors.startDate && (
                                <div className="department-error">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />&nbsp;&nbsp;&nbsp;{validationErrors.startDate}
                                </div>
                            )}
                        </div>}
                    {(type === 'mbom' && action !== 'delete') &&
                        <div className="editJob">
                            <span>End date</span>
                            <input
                                type="date"
                                placeholder="Enter date"
                                min={
                                    startDate
                                        ?startDate 
                                        : activeNode.start_date 
                                }
                                max={
                                    activeNode.part_number !== rootnode && activeNode.end_date
                                      
                                }
                                
                                onChange={(e) => handleChange('endDate', e.target.value)}
                                value={endDate}
                            />
                            {formSubmitted && validationErrors.endDate && (
                                <div className="department-error">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />&nbsp;&nbsp;&nbsp;{validationErrors.endDate}
                                </div>
                            )}
                        </div>}

                    <div className="btn-bottom">
                        {action === 'delete' && <button onClick={handleAddPart} className={`submit-edit-${!selectedParent ? 'errorbutton' : 'button'}`}>Save</button>}
                        {/* {action !== 'delete' && <button onClick={handleAddPart} className={`submit-edit-${!isSaveButtonEnabled ? 'errorbutton' : 'button'}`}>Save</button>} */}
                        {(action === 'add' && type === 'ebom') && <button onClick={handleAddPart} className={`submit-edit-${(!selectedOption || !quantity) ? 'errorbutton' : 'button'}`}>Save</button>}
                        {(action === 'update_quantity' && type === 'ebom') && <button onClick={handleAddPart} className={`submit-edit-${(!quantity || !selectedParent) ? 'errorbutton' : 'button'}`}>Save</button>}
                        {(action === 'replace' && type === 'ebom') && <button onClick={handleAddPart} className={`submit-edit-${(!selectedParent || !quantity || !selectedParent) ? 'errorbutton' : 'button'}`}>Save</button>}
                        {(action === 'add' && type === 'mbom') && <button onClick={handleAddPart} className={`submit-edit-${(!selectedOption || !quantity || !startDate || !endDate) ? 'errorbutton' : 'button'}`}>Save</button>}
                        {(action === 'update_quantity' && type === 'mbom') && <button onClick={handleAddPart} className={`submit-edit-${(!quantity || !selectedParent || !startDate || !endDate) ? 'errorbutton' : 'button'}`}>Save</button>}
                        {(action === 'replace' && type === 'mbom') && <button onClick={handleAddPart} className={`submit-edit-${(!selectedParent || !quantity || !selectedParent || !startDate || !endDate) ? 'errorbutton' : 'button'}`}>Save</button>}
                        <button onClick={handleClose} className="cancel-edit-button">Cancel</button>
                    </div>
                </div>
            </div>
            {openDeletePopUp && <DeletePartPopUp part_number={activeNode.part_number} action={action} type={type} setAction={setAction}
                onclose={handleCloseMenuActions} sequence_id={sequence_id} parent_part_number={selectedParent.part_number} setUpdatedData={setUpdatedData} />}
        </>

    );
}

export default AddPartsPopUp;
